import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavContainer, NavList, NavButton } from './Navigation.styles';
import { NavigationItem } from '../../interfaces/NavigationItem';

const navigationItems: NavigationItem[] = [
    { id: 'home', title: 'HOME', path: '/' },
    { id: 'products', title: 'PRODUCTS', path: '/products' },
    { id: 'menu', title: 'MENU', path: '/menu' },
    { id: 'about', title: 'ABOUT', path: '/about' },
    { id: 'contact', title: 'CONTACT', path: '/contact' },
];

const Navigation: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <NavContainer>
            <NavList>
                {navigationItems.map(({ id, title, path }) => (
                    <NavButton
                        key={id}
                        className={location.pathname === path ? 'active' : ''}
                        onClick={() => navigate(path)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        {title}
                    </NavButton>
                ))}
            </NavList>
        </NavContainer>
    );
};

export default Navigation;