import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as S from './Menu.styles';
import { menuCategories } from '../../../data/menuCategories';
import MenuSelectionCard from './MenuSelectionCard';

const menuSelectionContent = {
    food: {
        image: '/image/menu-food-cover.jpg',
        title: 'Menu de comida',
        description: 'Todos nuestros panes son de masa madre, eloborando con harina\n' +
            'de trigo y fermentado lentamente para desarrollar un sabor\n' +
            'profundo, exquisito y una miga aireada, ademas aporta beneficios\n' +
            'a la salud.\n' +
            '\n' +
            'La masa madre mejora la textura y sabor del pan debido a la levadura\n' +
            'natural y las bacterias que intervienen en su proceso de elaboracion \n' +
            'y la fermentacion ayuda a que haya un alto contenido de fibra. \n' +
            '\n' +
            'Toda nuestra pasteleria es hecha en casa con amor y dedicacion. \n' +
            'Durante los ultimos 7 años, hemos perfeccionado y mantenido \n' +
            'nuestros sabores tradicionales, brindando a cada bocado la \n' +
            'calidez y el sabor de lo hecho a mano.',
    },
    drinks: {
        image: '/image/menu-drinks-cover.jpg',
        title: 'Menu de bebidas',
        description: 'Todas nuestras bebidas y sus bases las preparamos exclusivamente en nuestro bar durante 7 años.\n' +
            'Utilizamos solo productos naturales y damos preferencia a los productos locales y a los agricultores.\n' +
            'Esto nos permite controlar todo el ciclo, la calidad y la frescura de los ingredientes.\n' +
            ' \n' +
            'Durante más de 5 años, tostamos nuestro propio café en nuestro tostador personal.\n' +
            'Seleccionamos cuidadosamente los perfiles de tostado y elegimos los mejores lotes de las fincas cafetaleras de Panamá,\n' +
            'y presentamos uno de los cafés de mayor calidad y sabor único en el mundo: la Geisha panameña y otros lotes exclusivos.\n',
    },
};

const Menu: React.FC = () => {
    const [activeCategory, setActiveCategory] = useState(0);
    const [menuType, setMenuType] = useState<'drinks' | 'food' | null>(null);

    const filteredCategories = menuType
        ? menuCategories.filter((category) => category.type === menuType)
        : [];

    const currentCategory = filteredCategories[activeCategory] || filteredCategories[0];

    const tabsContainerRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        isDragging.current = true;
        startX.current = e.pageX - (tabsContainerRef.current?.offsetLeft || 0);
        scrollLeft.current = tabsContainerRef.current?.scrollLeft || 0;
    };

    const handleMouseLeave = () => {
        isDragging.current = false;
    };

    const handleMouseUp = () => {
        isDragging.current = false;
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging.current || !tabsContainerRef.current) return;
        e.preventDefault();
        const x = e.pageX - tabsContainerRef.current.offsetLeft;
        const walk = (x - startX.current) * 1;
        tabsContainerRef.current.scrollLeft = scrollLeft.current - walk;
    };

    if (menuType === null) {
        return (
            <S.MenuTypeSelection>
                <MenuSelectionCard
                    onClick={() => setMenuType('food')}
                    image={menuSelectionContent.food.image}
                    title={menuSelectionContent.food.title}
                    description={menuSelectionContent.food.description}
                    index={0}
                />
                <MenuSelectionCard
                    onClick={() => setMenuType('drinks')}
                    image={menuSelectionContent.drinks.image}
                    title={menuSelectionContent.drinks.title}
                    description={menuSelectionContent.drinks.description}
                    index={1}
                />
            </S.MenuTypeSelection>
        );
    }

    return (
        <S.MenuContainer>
            <S.MenuContent>
                <S.BackButton
                    onClick={() => {
                        setMenuType(null);
                        setActiveCategory(0);
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    ← Back to Menu Selection
                </S.BackButton>

                <S.MenuTitle>
                    {menuType === 'food' ? 'Menu de comida' : 'Menu de bebidas'}
                </S.MenuTitle>

                <S.MenuTabs>
                    <S.TabsContainerWrapper>
                        <S.TabsContainer
                            ref={tabsContainerRef}
                            onMouseDown={handleMouseDown}
                            onMouseLeave={handleMouseLeave}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                        >
                            {filteredCategories.map((category, index) => (
                                <S.MenuTab
                                    key={category.category}
                                    isActive={activeCategory === index}
                                    onClick={() => setActiveCategory(index)}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {category.category}
                                </S.MenuTab>
                            ))}
                        </S.TabsContainer>
                    </S.TabsContainerWrapper>
                </S.MenuTabs>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeCategory}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 20 }}
                        transition={{
                            duration: 0.3,
                            when: 'beforeChildren',
                        }}
                    >
                        <S.CategorySubtitle>{currentCategory.subtitle}</S.CategorySubtitle>
                        <S.MenuItems>
                            {currentCategory.items.map((item, index) => (
                                <motion.div
                                    key={item.name}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                        duration: 0.3,
                                        delay: index * 0.1,
                                    }}
                                >
                                    <S.MenuCard
                                        whileHover={{
                                            y: -10,
                                            scale: 1.03,
                                            transition: { duration: 0.3 },
                                        }}
                                    >
                                        <S.MenuCardImage
                                            src={`/image/${item.image}`}
                                            alt={item.name}
                                            loading="lazy"
                                        />
                                        <S.MenuCardContent>
                                            <S.ItemTags>
                                                {item.tags.map((tag) => (
                                                    <S.Tag key={tag}>{tag}</S.Tag>
                                                ))}
                                            </S.ItemTags>
                                            <S.ItemInfo>
                                                <S.ItemName>
                                                    {item.name}
                                                    {item.secondaryName && (
                                                        <S.SecondaryName>{item.secondaryName}</S.SecondaryName>
                                                    )}
                                                </S.ItemName>
                                                <S.ItemDescription>{item.description}</S.ItemDescription>
                                                <S.PriceWrapper>
                                                    <S.ItemPrice>${item.price.toFixed(2)}</S.ItemPrice>
                                                    {item.secondaryPrice && (
                                                        <S.SecondaryPrice>
                                                            ${item.secondaryPrice.toFixed(2)}
                                                        </S.SecondaryPrice>
                                                    )}
                                                </S.PriceWrapper>
                                            </S.ItemInfo>
                                        </S.MenuCardContent>
                                    </S.MenuCard>
                                </motion.div>
                            ))}
                        </S.MenuItems>
                    </motion.div>
                </AnimatePresence>
            </S.MenuContent>
        </S.MenuContainer>
    );
};

export default Menu;