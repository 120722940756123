// src/styles/common.ts
import styled from 'styled-components';

export const PageContainer = styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    padding: ${({ theme }) => theme.containerPadding};
    min-height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr auto;
`;

export const CommonPageContainer = styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 80px auto 0;
    padding: 0 ${({ theme }) => theme.containerPadding};
    font-family: 'Bebas Neue', sans-serif;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};

    @media (max-width: 768px) {
        margin-top: 60px;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(
    auto-fit,
    minmax(clamp(150px, 20vw, 300px), 1fr)
  );
    gap: ${({ theme }) => theme.spacing.md};
`;

export const FlexContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.md};
    flex-wrap: wrap;
`;
