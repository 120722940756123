import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CardContainer = styled(motion.div)`
    flex: 1 1 calc(50% - ${({ theme }) => theme.spacing.md});
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.background};
    cursor: pointer;
    max-width: 600px;
    margin: 0 auto;

    @media (max-width: 768px) {
        flex: 1 1 100%;
        max-width: 100%;
    }
`;

export const CardImageWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-top: 66.67%; // Соотношение сторон 3:2
    overflow: hidden;
`;

export const CardImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;

    ${CardContainer}:hover & {
        transform: scale(1.05);
    }
`;

export const CardContent = styled.div`
    padding: ${({ theme }) => theme.spacing.md};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%; // Занимает всё доступное пространство
`;

export const CardTitle = styled.h2`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.xl};
    margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
    color: ${({ theme }) => theme.colors.text};
`;

export const CardDescription = styled.p`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 1.4;
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.9;
    white-space: pre-line;
    text-align: left;
`;