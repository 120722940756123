// src/components/pages/home/Home.styles.ts
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const HomeContainer = styled.div`
    margin-top: clamp(80px, 10vh, 100px); // Отступ для фиксированной навигации
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 80dvh;
    background-color: ${({ theme }) => theme.colors.background};
    color: #FFFFFF;
    padding: clamp(20px, 4vw, 40px);
    max-width: ${({ theme }) => theme.maxWidth};
    overflow-x: hidden;
`;

export const Logo = styled.div`
    width: clamp(300px, 45%, 600px);
    margin-bottom: clamp(60px, 10vh, 120px);

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px;
        width: 80%;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    gap: clamp(20px, 4vw, 40px);
    justify-content: center;
    width: 100%;
    margin-bottom: clamp(40px, 8vh, 80px);
    padding: 0 20px;
    max-width: 500px;

    @media (max-width: 768px) {
        gap: 20px;
        padding: 0;
        width: 90%;
    }
`;

export const NavCard = styled(motion.div)`
    width: clamp(100px, 10vw, 120px);
    height: clamp(100px, 10vw, 120px);
    background: linear-gradient(135deg, #1a1a1a, #1e1e1e);
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    flex: 1;

    @media (max-width: 768px) {
        width: 90px;
        height: 90px;
    }
`;

export const CardImage = styled.img`
    width: 70%;
    height: 70%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
`;

export const CardTitle = styled.div`
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    font-size: clamp(14px, 1.2vw, 18px);
    color: #FFFFFF;
    text-align: center;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.3s ease;
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;

    ${NavCard}:hover & {
        opacity: 1;
        transform: translateY(0);
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
