// src/styles/GlobalStyles.ts
import { createGlobalStyle } from 'styled-components';
import 'maplibre-gl/dist/maplibre-gl.css';

export const GlobalStyles = createGlobalStyle`
    body, html, #root {
        margin: 0;
        padding: 0;
        width: 100%;
        min-height: 100%;
        font-family: 'Bebas Neue', sans-serif;
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.text};
        overflow-x: hidden; /* Предотвращение горизонтального скролла */
        /* Убираем overflow-y: hidden, чтобы позволить вертикальную прокрутку */
    }

    * {
        box-sizing: border-box;
    }

    /* Стили маркера */
    .marker {
        width: ${({ theme }) => theme.spacing.md};
        height: ${({ theme }) => theme.spacing.md};
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.white};
        cursor: pointer;
        box-shadow: 0 0 ${({ theme }) => theme.spacing.sm} rgba(0, 0, 0, 0.5);
    }

    /* Стили скроллбара */
    ::-webkit-scrollbar {
        width: ${({ theme }) => theme.spacing.xs};
        height: ${({ theme }) => theme.spacing.xs};
    }

    ::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.colors.background};
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 26px;
    }

    /* Анимации */
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes slideUp {
        from {
            transform: translateY(${({ theme }) => theme.spacing.md});
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;
