// src/components/layout/Layout.styles.ts
import styled from 'styled-components';

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    align-items: center;
    justify-content: flex-start; /* Изменено с center на flex-start */
    text-align: center;
`;

export const Container = styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
    padding: ${({ theme }) => theme.containerPadding};
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MainContent = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
`;
