import { Product } from '../interfaces/Product';

export const productsData: Product[] = [
    {
        id: 'hartmann-maragogipe',
        name: 'HARTMANN MARAGOGIPE',
        type: 'NATURAL',
        description: 'Finca: Hartmann\n' +
            'Región: Volcán, Santa Clara\n' +
            'Altura: 1350 msnm\n' +
            'Procesamiento:Natural tradicional, seleccionado a mano\n' +
            'Productor: Ratibor Hartmann\n' +
            '\n' +
            'Alimento seleccionado a mano y respetando el medio ambiente.\n' +
            '\n' +
            'Perfil de sabor:\n' +
            'Acidez media, sabor intenso con notas brillantes de manzana roja \n' +
            'y ciruela seca, complementadas con matices de chocolate.\n' +
            'En el retrogusto, se percibe un ligero sabor cremoso.\n',
        price: 19,
        image: 'hartmann-maragogipe.jpg',
        tags: ['Popular', 'Sweet Remnants']
    },
    {
        id: 'mil-cumbres-pacamara',
        name: 'MIL CUMBRES PACAMARA',
        type: 'NATURAL',
        description: 'Finca: Mil Cumbres\n' +
            'Región: Cordillera. Valle de Nubes.\n' +
            'Altura: 1500-1650 masl\n' +
            'Proceso: Natural\n' +
            'Productor: Mario Fonseca Imendia\n' +
            '\n' +
            'Alimento seleccionado a mano y respetando el medio ambiente.\n' +
            '\n' +
            'Perfil de sabor: Baja acidez,\n' +
            'Notas intensas de chocolate, matizadas por el dulce néctar de la miel.\n' +
            'Sutiles toques de naranja madura se entrelazan con la jugosidad \n' +
            'de la pulpa de café.\n' +
            'El final delicado se adorna con el aroma etéreo y amargo \n' +
            'de la cáscara de pomelo.\n',
        price: 19,
        image: 'mil-cumbres-pacamara.jpg',
        tags: ['Recommended', 'Popular']
    },
    {
        id: 'corpachi-caturra',
        name: 'CORPACHI CATURRA',
        type: 'NATURAL',
        description: 'Finca: Corpachi Coffee Company\n' +
            'Región: Cordillera, Chiriquí\n' +
            'Altura: 1300 a 1450 msnm\n' +
            'Procesamiento: Natural tradicional\n' +
            'Productor: Edgar Gutiérrez\n' +
            '\n' +
            'Secado intermitente\n' +
            'Camas africanas y cuarto oscuro con deshumidificadores.\n' +
            '\n' +
            'Perfil de sabor:\n' +
            'Acidez brillante,\n' +
            'Notas dulces de frutas tropicales (como mango y piña) y matices \n' +
            'suaves de chocolate y caramelo.\n' +
            'En el retrogusto, se percibe una nota floral ligera.',
        price: 15,
        image: 'corpachi-caturra.jpg',
        tags: ['Recommended', 'Popular', 'Experimental']
    },
    {
        id: 'mil-cumbres-san-ramon',
        name: 'MIL CUMBRES SAN RAMON',
        type: 'NATURAL',
        description: 'Finca: Mil Cumbres\n' +
            'Región: Cordillera. Valle de Nubes.\n' +
            'Altura: 1500-1650 masl\n' +
            'Proceso: Natural\n' +
            'Productor: Mario Fonseca Imendia\n' +
            '\n' +
            'Alimento seleccionado a mano y respetando el medio ambiente.\n' +
            '\n' +
            'Perfil de sabor:\n' +
            'Acidez media,\n' +
            'Sabor intenso con notas brillantes de frutas rojas \n' +
            '(como manzana y ciruela), complementadas con matices de \n' +
            'cedro y almendra.\n' +
            'En el retrogusto, se percibe un ligero sabor cremoso.',
        price: 19,
        image: 'mil-cumbres-san-ramon.jpg',
        tags: ['Recommended', 'Popular', 'Experimental']
    },
    {
        id: 'mil-cumbres-geisha',
        name: 'MIL CUMBRES GEISHA',
        type: 'NATURAL',
        description: 'Finca: Mil Cumbres\n' +
            'Región: Cordillera. Valle de Nubes.\n' +
            'Altura: 1660-1700 masl\n' +
            'Proceso: Natural\n' +
            'Productor: Mario Fonseca Imendia\n' +
            '\n' +
            'Alimento seleccionado a mano y respetando el medio ambiente.\n' +
            '\n' +
            'Perfil de sabor:\n' +
            'Alta acidez, sabor exquisito con notas brillantes de bergamota, \n' +
            'jazmín y lemongrass.\n' +
            'El sabor se complementa con notas dulces de moras negras y, \n' +
            'en el retrogusto, se encuentra una elegante dulzura frutal.',
        price: 35,
        image: 'mil-cumbres-geisha.jpg',
        tags: ['Recommended', 'Popular', 'New']
    }
];