// src/components/pages/menu/Menu.styles.ts
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CommonPageContainer } from '../../../styles/common';

export const MenuContainer = styled(CommonPageContainer)``;

export const MenuContent = styled.div`
    width: 100%;
`;


export const MenuTypeSelection = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.md};
    width: 100%;
    padding: ${({ theme }) => theme.containerPadding};
    background-color: ${({ theme }) => theme.colors.background};
    align-items: stretch; // Важно для одинаковой высоты
    justify-content: center;
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing.md};
    }
`;


export const MenuTypeCard = styled(motion.div)`
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.background};
    cursor: pointer;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 768px) {
        max-width: none;
    }
`;

export const MenuTypeImageWrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const MenuTypeImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 0.3s ease;

    ${MenuTypeCard}:hover & {
        transform: scale(1.05);
    }
`;

export const MenuTypeContent = styled.div`
    padding: ${({ theme }) => theme.spacing.md};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const MenuTypeTitle = styled.h2`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.xl};
    margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
    color: ${({ theme }) => theme.colors.text};
`;

export const MenuTypeDescription = styled.p`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 1.4;
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.9;
    white-space: pre-line;
    text-align: left;
`;

// Исправляем BackButton
export const BackButton = styled(motion.button)`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.text};
    border: 2px solid ${({ theme }) => theme.colors.text};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
    margin-bottom: ${({ theme }) => theme.spacing.md};
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-family: 'Bebas Neue', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.sm};
    position: relative;
    z-index: 1;

    &:hover {
        background-color: ${({ theme }) => theme.colors.text};
        color: ${({ theme }) => theme.colors.background};
    }

    @media (max-width: 768px) {
        font-size: ${({ theme }) => theme.fontSize.md};
        padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
        margin-left: 0; // Убираем отступ слева на мобильных
        width: auto; // Автоматическая ширина
    }
`;

export const MenuTitle = styled.h1`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.xxl};
    color: ${({ theme }) => theme.colors.text};
    margin: 0 0 ${({ theme }) => theme.spacing.md} 0;
    text-align: left;
`;

export const TabsContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.lg};
    overflow-x: auto;
    margin-bottom: ${({ theme }) => theme.spacing.md};
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    padding: 0; // Убираем padding слева

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const MenuTabs = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const TabsContainerWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const MenuTab = styled(motion.button)<{ isActive: boolean }>`
    background-color: transparent;
    border: none;
    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-family: 'Bebas Neue', sans-serif;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    flex-shrink: 0;
    text-align: left;
    white-space: nowrap;
    text-shadow: ${({ isActive, theme }) =>
            isActive
                    ? `0 0 ${theme.spacing.sm} rgba(255, 255, 255, 1)`
                    : `0 0 ${theme.spacing.sm} rgba(255, 255, 255, 0.2)`};

    &:hover {
        text-shadow: 0 0 ${({ theme }) => theme.spacing.sm} rgba(255, 255, 255, 1);
    }
`;

export const CategorySubtitle = styled.h3`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.lg};
    color: ${({ theme }) => theme.colors.text};
    margin: ${({ theme }) => `${theme.spacing.lg} 0 ${theme.spacing.md} 0`};
    text-align: left;
`;

export const MenuItems = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: ${({ theme }) => theme.spacing.md};
`;

export const MenuCard = styled(motion.div)`
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const MenuCardImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: all 0.3s ease;

    ${MenuCard}:hover & {
        filter: grayscale(0%);
        transform: scale(1.05);
    }
`;

export const MenuCardContent = styled.div`
    padding: ${({ theme }) => theme.spacing.sm};
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.background};
    align-items: flex-start; /* Выравниваем содержимое по левому краю */
`;

export const ItemTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.xs}; /* Добавляем отступ между тегами */
    margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const Tag = styled.span`
    background-color: rgba(255, 255, 255, 0.8);
    color: ${({ theme }) => theme.colors.background};
    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-family: 'Bebas Neue', sans-serif;
`;

export const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start; /* Выравниваем содержимое по левому краю */
`;

export const ItemName = styled.h3`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    color: ${({ theme }) => theme.colors.text};
    text-align: left;
    display: flex; /* Добавлено */
    flex-direction: column; /* Добавлено */
`;


export const SecondaryName = styled.span`
    font-size: ${({ theme }) => theme.fontSize.md};
    opacity: 0.9;
    margin-top: ${({ theme }) => theme.spacing.xs}; /* Добавлено */
`;


export const ItemDescription = styled.p`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: #ccc;
    flex-grow: 1;
    text-align: left; /* Выравниваем текст по левому краю */
`;

export const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выравниваем цену по левому краю */
    margin-top: auto;
    gap: ${({ theme }) => theme.spacing.xs};
`;

export const ItemPrice = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.lg};
    color: ${({ theme }) => theme.colors.text};
`;

export const SecondaryPrice = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.9;
`;
