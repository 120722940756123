import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CommonPageContainer } from '../../../styles/common';

export const ProductsContainer = styled(CommonPageContainer)``;

export const ProductsContent = styled.div`
    width: 100%;
`;

export const Title = styled.h1`
    font-size: ${({ theme }) => theme.fontSize.xxl};
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: ${({ theme }) => theme.spacing.lg};
    text-align: left;
`;

export const ProductsGrid = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(clamp(200px, 30%, 300px), 1fr));
    gap: ${({ theme }) => theme.spacing.md};
`;

export const ProductCard = styled(motion.div)`
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
`;

export const ProductImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: all 0.3s ease;

    ${ProductCard}:hover & {
        filter: grayscale(0%);
    }
`;

export const ProductContent = styled.div`
    padding: ${({ theme }) => theme.spacing.sm};
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.background};
`;

export const ExpandedContent = styled(motion.div)`
    overflow: hidden;
`;

export const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.xs};
    margin: ${({ theme }) => theme.spacing.sm} 0;
    align-items: flex-start;
`;

export const Tag = styled(motion.span)`
    background-color: rgba(255, 255, 255, 0.8);
    color: ${({ theme }) => theme.colors.background};
    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-family: 'Bebas Neue', sans-serif;
    text-align: left;
`;

export const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
`;

export const ProductType = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: #888;
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
`;

export const ProductName = styled.h3`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    color: ${({ theme }) => theme.colors.text};
    text-align: left;
    width: 100%;
`;

export const ProductDescription = styled.p`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: #ccc;
    text-align: left;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    white-space: pre-line;
    line-height: 1.5;
`;

export const PriceWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const ProductPrice = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.lg};
    color: ${({ theme }) => theme.colors.text};
    text-align: left;
`;

export const BuyButton = styled.button`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    padding: ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.fontSize.md};
    font-family: 'Bebas Neue', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
    letter-spacing: 1px;
    text-shadow: 0 0 ${({ theme }) => theme.spacing.md} rgba(255, 255, 255, 0.3);
    text-align: center;
    margin-top: auto;
    border: 2px solid ${({ theme }) => theme.colors.text};

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 ${({ theme }) => theme.spacing.lg} rgba(255, 255, 255, 0.2);
        background-color: ${({ theme }) => theme.colors.text};
        color: ${({ theme }) => theme.colors.background};
    }

    &:active {
        transform: translateY(0);
    }

    /* Останавливаем всплытие клика для кнопки */
    z-index: 2;
    position: relative;
`;