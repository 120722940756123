import React from 'react';
import * as S from './MenuSelectionCard.styles';
import { motion } from 'framer-motion';

interface MenuSelectionCardProps {
    onClick: () => void;
    image: string;
    title: string;
    description: string;
    index: number;
}

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: i * 0.2,
            duration: 0.5,
        },
    }),
};

const MenuSelectionCard: React.FC<MenuSelectionCardProps> = ({
                                                                 onClick,
                                                                 image,
                                                                 title,
                                                                 description,
                                                                 index,
                                                             }) => {
    return (
        <S.CardContainer
            onClick={onClick}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            custom={index}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
        >
            <S.CardImageWrapper>
                <S.CardImage src={image} alt={title} loading="eager" />
            </S.CardImageWrapper>
            <S.CardContent>
                <S.CardTitle>{title}</S.CardTitle>
                <S.CardDescription>{description}</S.CardDescription>
            </S.CardContent>
        </S.CardContainer>
    );
};

export default MenuSelectionCard;