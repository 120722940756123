// src/components/pages/contact/Contact.styles.ts
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CommonPageContainer } from '../../../styles/common';

export const ContactContainer = styled(CommonPageContainer)`
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
`;

export const ContactContent = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
`;


export const ContactHeader = styled.div`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.md};

    h1 {
        font-size: ${({ theme }) => theme.fontSize.xxl};
        margin: 0;
        letter-spacing: 2px;
        text-shadow: 0 0 ${({ theme }) => theme.spacing.sm} rgba(255, 255, 255, 0.7);
    }

    p {
        font-size: ${({ theme }) => theme.fontSize.md};
        color: #cccccc;
        letter-spacing: 1px;
        margin-top: ${({ theme }) => theme.spacing.sm};
    }
`;

export const ContactBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: ${({ theme }) => theme.spacing.lg};
    flex: 1;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`;

export const ContactLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.md};
    max-width: 400px;
    width: 100%;

    @media (max-width: 1024px) {
        max-width: none;
        width: 100%;
        margin: 0 auto;
    }
`;

export const CoffeeShopsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
`;

export const CoffeeShopCard = styled(motion.div)<{ isActive: boolean }>`
    background-color: ${({ isActive, theme }) =>
            isActive ? theme.colors.background : '#2a2a2a'};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: ${({ theme }) => theme.spacing.sm};
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px ${({ theme }) => theme.spacing.lg} rgba(0, 0, 0, 0.2);

    h3 {
        font-size: ${({ theme }) => theme.fontSize.lg};
        margin-bottom: ${({ theme }) => theme.spacing.xs};
        color: ${({ theme }) => theme.colors.text};
        text-shadow: ${({ isActive, theme }) =>
                isActive
                        ? `0 0 ${theme.spacing.sm} rgba(255, 255, 255, 1)`
                        : `0 0 ${theme.spacing.sm} rgba(255, 255, 255, 0.8)`};
    }

    p {
        font-size: ${({ theme }) => theme.fontSize.sm};
        color: #cccccc;
        margin: 0;
    }

    .hours {
        margin-top: ${({ theme }) => theme.spacing.xs};
        font-size: ${({ theme }) => theme.fontSize.xs};
        color: #888;

        span {
            color: ${({ theme }) => theme.colors.text};
        }
    }

    .phone {
        margin-top: ${({ theme }) => theme.spacing.xs};
        font-size: ${({ theme }) => theme.fontSize.sm};
        color: ${({ theme }) => theme.colors.text};
        text-shadow: ${({ isActive }) =>
                isActive ? '0 0 10px rgba(255, 255, 255, 0.5)' : 'none'};
    }

    &:hover {
        transform: translateY(-2px);
        background-color: ${({ theme }) => theme.colors.background};
        box-shadow: 0 0 ${({ theme }) => theme.spacing.lg} rgba(255, 255, 255, 0.1);
    }
`;

export const ContactForm = styled(motion.form)`
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: ${({ theme }) => theme.spacing.md};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.sm};
    box-shadow: 0 4px ${({ theme }) => theme.spacing.lg} rgba(0, 0, 0, 0.2);

    input,
    textarea {
        width: 100%;
        background-color: #3a3a3a;
        border: none;
        border-radius: ${({ theme }) => theme.borderRadius.sm};
        padding: ${({ theme }) => theme.spacing.sm};
        color: ${({ theme }) => theme.colors.text};
        font-size: ${({ theme }) => theme.fontSize.sm};
        font-family: 'Bebas Neue', sans-serif;

        &::placeholder {
            color: #888;
        }

        &:focus {
            outline: none;
            background-color: ${({ theme }) => theme.colors.background};
            box-shadow: 0 0 ${({ theme }) => theme.spacing.md} rgba(255, 255, 255, 0.1);
        }
    }

    textarea {
        resize: vertical;
        min-height: 114px;
        max-height: 150px;
    }

    button {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.text};
        border: none;
        border-radius: ${({ theme }) => theme.borderRadius.sm};
        padding: ${({ theme }) => theme.spacing.sm};
        font-size: ${({ theme }) => theme.fontSize.md};
        font-family: 'Bebas Neue', sans-serif;
        cursor: pointer;
        transition: all 0.3s ease;
        letter-spacing: 1px;
        text-shadow: 0 0 ${({ theme }) => theme.spacing.md} rgba(255, 255, 255, 0.3);

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 0 ${({ theme }) => theme.spacing.lg} rgba(255, 255, 255, 0.2);
        }

        &:active {
            transform: translateY(0);
        }
    }
`;

export const ContactRight = styled.div`
    width: 100%;
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    overflow: hidden;
    box-shadow: 0 4px ${({ theme }) => theme.spacing.lg} rgba(0, 0, 0, 0.3);

    /* Maintain aspect ratio */
    aspect-ratio: 16 / 9;

    @media (max-width: 1024px) {
        aspect-ratio: 4 / 3;
    }

    @media (max-width: 768px) {
        aspect-ratio: 1 / 1;
    }
`;

export const MapContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .mapboxgl-ctrl-top-right {
        top: ${({ theme }) => theme.spacing.sm};
        right: ${({ theme }) => theme.spacing.sm};
    }

    .mapboxgl-ctrl-group {
        background-color: #2a2a2a;
        border: none;
        box-shadow: 0 0 ${({ theme }) => theme.spacing.md} rgba(0, 0, 0, 0.3);

        button {
            background-color: #2a2a2a;

            span {
                background-color: ${({ theme }) => theme.colors.text};
            }
        }
    }

    .mapboxgl-popup {
        z-index: 2;
    }

    .mapboxgl-popup-content {
        background-color: #2a2a2a;
        color: ${({ theme }) => theme.colors.text};
        border-radius: ${({ theme }) => theme.borderRadius.md};
        padding: ${({ theme }) => theme.spacing.md};
        box-shadow: 0 4px ${({ theme }) => theme.spacing.lg} rgba(0, 0, 0, 0.3);
        min-width: 200px;
        font-family: 'Bebas Neue', sans-serif;
    }

    .mapboxgl-popup-close-button {
        color: ${({ theme }) => theme.colors.text};
        font-size: ${({ theme }) => theme.fontSize.lg};
        padding: ${({ theme }) => theme.spacing.xs};
        right: ${({ theme }) => theme.spacing.xs};
        top: ${({ theme }) => theme.spacing.xs};

        &:hover {
            background: none;
            color: rgba(255, 255, 255, 0.8);
        }
    }

    .mapboxgl-popup-tip {
        display: none;
    }

    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
        display: none;
    }
`;
