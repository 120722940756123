// src/data/coffeeShops.ts
import { CoffeeShop } from '../interfaces/CoffeeShop';

export const coffeeShops: CoffeeShop[] = [
    {
        id: 'san-francisco',
        name: "LETO COFFEE BREW BAR | SAN FRANCISCO",
        address: "PLAZA 76, CALLE 76",
        hours: {
            weekdays: "8:00 AM - 5:00 PM",
            weekends: "8:00 AM - 5:00 PM"
        },
        phone: "+507 6579-4186",
        lat: 8.991403337401868,
        lng: -79.50288977123506,
        color: "#A57B0A",
        images: {
            main: "san-francisco-main.jpg",
            interior: ["sf-interior-1.jpg", "sf-interior-2.jpg", "sf-interior-3.jpg"]
        }
    },
    {
        id: 'costa-del-este',
        name: "LETO COFFEE BREW BAR | COSTA DEL ESTE",
        address: "AV. COSTA DEL MAR, PANAMÁ",
        hours: {
            weekdays: "7:00 AM - 5:00 PM",
            weekends: "8:00 AM - 5:00 PM"
        },
        phone: "+507 6580-2761",
        lat: 9.011092611281557,
        lng: -79.46483358355734,
        color: "#A57B0A",
        images: {
            main: "costa-del-este-main.jpg",
            interior: [
                "cde-interior-1.jpg",
                "cde-interior-2.jpg",
                "cde-interior-3.jpg"
            ]
        }
    },
    {
        id: 'obarrio',
        name: "LETO COFFEE BREW BAR | OBARRIO",
        address: "C. 61 ESTE 61, PANAMÁ",
        hours: {
            weekdays: "7:00 AM - 6:00 PM",
            weekends: "8:00 AM - 5:00 PM"
        },
        phone: "+507 6633-2668",
        lat: 8.987779175948331,
        lng: -79.51612462725386,
        color: "#A57B0A",
        images: {
            main: "obarrio-main.jpg",
            interior: [
                "ob-interior-1.jpg",
                "ob-interior-2.jpg",
                "ob-interior-3.jpg"
            ]
        }
    }
];

// Рекомендуемые изображения для каждой локации:
/*
San Francisco:
- sf-interior-1.jpg: Показывает барную стойку с кофемашинами
- sf-interior-2.jpg: Основной зал с сидячими местами
- sf-interior-3.jpg: Уголок с витриной десертов

Costa del Este:
- cde-interior-1.jpg: Современный интерьер с большими окнами
- cde-interior-2.jpg: Зона для работы с ноутбуками
- cde-interior-3.jpg: Открытая терраса с видом на город

Obarrio:
- ob-interior-1.jpg: Минималистичный интерьер с деревянными элементами
- ob-interior-2.jpg: Уютная зона отдыха с диванами
- ob-interior-3.jpg: Кофейный бар с процессом приготовления
*/