// src/components/pages/contact/Contact.tsx
import React, { useState, useEffect, useRef } from 'react';
import { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import * as S from './Contact.styles';
import { coffeeShops } from '../../../data/coffeeShops';

const MAP_STYLE_URL = '/styles/DarkMatter/style.json';

const GlobalStyles = createGlobalStyle`
    .custom-marker {
        position: relative;
        width: ${({ theme }) => theme.spacing.sm};
        height: ${({ theme }) => theme.spacing.sm};
        border-radius: 50%;
        border: 3px solid darkgoldenrod;
        cursor: pointer;
        box-shadow: 0 0 ${({ theme }) => theme.spacing.md} rgba(0, 0, 0, 0.5);
        transform: translate(-50%, -50%);
    }

    .custom-marker.active-marker::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: ${({ theme }) => theme.spacing.lg};
        height: ${({ theme }) => theme.spacing.lg};
        margin-left: calc(-1 * ${({ theme }) => theme.spacing.md});
        margin-top: calc(-1 * ${({ theme }) => theme.spacing.md});
        border-radius: 50%;
        background-color: inherit;
        animation: pulse 2s infinite;
        opacity: 0.7;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 0.7;
        }
        70% {
            transform: scale(2.5);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
`;

const Contact: React.FC = () => {
    const [activeShop, setActiveShop] = useState(0);
    const [mapLoaded, setMapLoaded] = useState(false);
    const mapContainer = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<maplibregl.Map | null>(null);
    const markers = useRef<maplibregl.Marker[]>([]);
    const popups = useRef<maplibregl.Popup[]>([]);

    // Инициализация карты
    useEffect(() => {
        if (!mapContainer.current) return;

        const map = new maplibregl.Map({
            container: mapContainer.current,
            style: MAP_STYLE_URL,
            center: [coffeeShops[0].lng, coffeeShops[0].lat],
            zoom: 12,
            attributionControl: false,
            dragRotate: false,
            pitchWithRotate: false,
            preserveDrawingBuffer: true,
        });

        mapInstance.current = map;
        map.touchZoomRotate.disableRotation();

        map.addControl(
            new maplibregl.NavigationControl({
                showCompass: false,
            })
        );

        // Создаем и добавляем маркеры после полной загрузки карты
        map.on('load', () => {
            markers.current.forEach((marker) => marker.remove());
            popups.current.forEach((popup) => popup.remove());
            markers.current = [];
            popups.current = [];

            coffeeShops.forEach((shop, index) => {
                const markerElement = document.createElement('div');
                markerElement.className = 'custom-marker';
                markerElement.style.backgroundColor = shop.color;

                // Добавляем эффект мигания для активного маркера
                if (index === activeShop) {
                    markerElement.classList.add('active-marker');
                }

                const popup = new maplibregl.Popup({
                    offset: 25,
                    closeButton: true,
                    closeOnClick: false,
                }).setHTML(`
          <div style="font-family: 'Bebas Neue', sans-serif; color: #ffffff;">
            <h3 style="font-size: 18px; margin: 0; color: ${shop.color};">
              ${shop.name}
            </h3>
            <p style="margin: 8px 0;">
              ${shop.address}
            </p>
            <div style="color: #888; font-size: 14px;">
              MON-FRI: <span style="color: #ffffff">${shop.hours.weekdays}</span><br/>
              SAT-SUN: <span style="color: #ffffff">${shop.hours.weekends}</span>
            </div>
            <div style="color: ${shop.color}; margin-top: 8px;">
              ${shop.phone}
            </div>
          </div>
        `);

                popups.current.push(popup);

                const marker = new maplibregl.Marker({
                    element: markerElement,
                    anchor: 'center',
                })
                    .setLngLat([shop.lng, shop.lat])
                    .setPopup(popup)
                    .addTo(map);

                markers.current.push(marker);

                markerElement.addEventListener('click', () => {
                    setActiveShop(index);
                });

                if (index === activeShop) {
                    popup.addTo(map);
                }
            });

            const bounds = new maplibregl.LngLatBounds();
            coffeeShops.forEach((shop) => {
                bounds.extend([shop.lng, shop.lat]);
            });

            map.fitBounds(bounds, {
                padding: 50,
            });

            setMapLoaded(true);
        });

        return () => {
            markers.current.forEach((marker) => marker.remove());
            popups.current.forEach((popup) => popup.remove());
            if (mapInstance.current) {
                mapInstance.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (!mapInstance.current || !mapLoaded) return;

        const currentMap = mapInstance.current;
        const shop = coffeeShops[activeShop];

        currentMap.flyTo({
            center: [shop.lng, shop.lat],
            zoom: 15,
            speed: 1.2,
        });

        markers.current.forEach((marker, index) => {
            const element = marker.getElement();
            if (index === activeShop) {
                element.classList.add('active-marker');
                marker.getPopup()?.addTo(currentMap);
            } else {
                element.classList.remove('active-marker');
                marker.getPopup()?.remove();
            }
        });
    }, [activeShop, mapLoaded]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        console.log({
            name: formData.get('name'),
            email: formData.get('email'),
            message: formData.get('message'),
        });

        alert('Thank you for your message! We will get back to you shortly.');
        form.reset();
    };

    return (
        <S.ContactContainer>
            <GlobalStyles />
            <S.ContactContent>
                <S.ContactHeader>
                    <motion.h1
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        GET IN TOUCH
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        VISIT OUR COFFEE SHOPS OR LEAVE US A MESSAGE
                    </motion.p>
                </S.ContactHeader>

                <S.ContactBody>
                    <S.ContactLeft>
                        <S.CoffeeShopsList>
                            {coffeeShops.map((shop, index) => (
                                <S.CoffeeShopCard
                                    key={shop.id}
                                    isActive={activeShop === index}
                                    onClick={() => setActiveShop(index)}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <h3>{shop.name}</h3>
                                    <p>{shop.address}</p>
                                    <div className="hours">
                                        MON-FRI: <span>{shop.hours.weekdays}</span>
                                        <br />
                                        SAT-SUN: <span>{shop.hours.weekends}</span>
                                    </div>
                                    <div className="phone">{shop.phone}</div>
                                </S.CoffeeShopCard>
                            ))}
                        </S.CoffeeShopsList>

                        <S.ContactForm onSubmit={handleSubmit}>
                            <input type="text" name="name" placeholder="YOUR NAME" required />
                            <input type="email" name="email" placeholder="YOUR EMAIL" required />
                            <textarea name="message" placeholder="YOUR MESSAGE" required />
                            <motion.button
                                type="submit"
                                whileHover={{ y: -3 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                SEND MESSAGE
                            </motion.button>
                        </S.ContactForm>
                    </S.ContactLeft>

                    <S.ContactRight>
                        <S.MapContainer ref={mapContainer} />
                    </S.ContactRight>
                </S.ContactBody>
            </S.ContactContent>
        </S.ContactContainer>
    );
};

export default Contact;
