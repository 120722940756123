// src/components/layout/Layout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from '../navigation/Navigation';
import { PageWrapper, Container, MainContent } from './Layout.styles';

const Layout: React.FC = () => {
    return (
        <PageWrapper>
            <Navigation />
            <MainContent>
                <Container>
                    <Outlet />
                </Container>
            </MainContent>
        </PageWrapper>
    );
};

export default Layout;
