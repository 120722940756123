import React from 'react';
import { motion } from 'framer-motion';
import {
    HomeContainer,
    Logo,
    CardContainer,
    NavCard,
    CardImage,
    CardTitle,
} from './Home.styles';

const cards = [
    { title: 'FARM', image: '/image/FARM_X.svg', alt: 'Coffee bean' },
    { title: 'BREW', image: '/image/BREW_X.svg', alt: 'Heart' },
    { title: 'ROAST', image: '/image/ROAST_X.svg', alt: 'Fire' },
];

const Home: React.FC = () => {
    return (
        <HomeContainer>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Logo>
                    <img src="/image/COFFEETHERAPYGROUP.svg" alt="Coffee Therapy Group" />
                </Logo>
                <CardContainer>
                    {cards.map((card) => (
                        <NavCard
                            key={card.title}
                            whileHover={{
                                y: -5,
                                transition: { duration: 0.2 }
                            }}
                        >
                            <CardImage src={card.image} alt={card.alt} />
                            <CardTitle>{card.title}</CardTitle>
                        </NavCard>
                    ))}
                </CardContainer>
            </motion.div>
        </HomeContainer>
    );
};

export default Home;