export const theme = {
    maxWidth: '1920px',
    spacing: {
        xs: 'clamp(4px, 1vw, 8px)',
        sm: 'clamp(8px, 2vw, 16px)',
        md: 'clamp(16px, 3vw, 32px)',
        lg: 'clamp(32px, 5vw, 64px)',
        xl: 'clamp(48px, 6vw, 96px)',
    },
    fontSize: {
        xs: 'clamp(12px, 1vw, 14px)',
        sm: 'clamp(14px, 1.5vw, 16px)',
        md: 'clamp(16px, 2vw, 20px)',
        lg: 'clamp(20px, 2.5vw, 24px)',
        xl: 'clamp(24px, 3vw, 28px)',
        xxl: 'clamp(28px, 3.5vw, 32px)',
    },
    containerPadding: 'clamp(16px, 4vw, 80px)',
    borderRadius: {
        sm: 'clamp(5px, 1vw, 10px)',
        md: 'clamp(10px, 2vw, 20px)',
        lg: 'clamp(15px, 3vw, 30px)',
    },

    colors: {
        background: '#1a1a1a',
        text: '#ffffff',
        white: '#ffffff',
    },
};