import { MenuCategory } from '../interfaces/MenuItem';

export const menuCategories: MenuCategory[] = [
    // Food Categories
    {
        category: 'BRUNCH',
        subtitle: 'MAKE WEEKENDS GREAT AGAIN!',
        type: 'food',
        items: [
            {
                name: 'LETO STYLE EGGS BOWL',
                description: 'Nuestro bowl es una explosión de sabores. Comienza con una base \n' +
                    'de escalivada, seguida de huevos fritos, salchichas artesanales \n' +
                    'de desayuno, refrito de tomate y cebollina cortada, todo \n' +
                    'acompañado de tostadas de pan campesino de masa madre para \n' +
                    'disfrutar al máximo de esta experiencia culinaria única',
                price: 12,
                tags: ['Special', 'Brunch', 'Original Recipe'],
                image: 'leto-style-eggs-bowl.jpg'
            },
            {
                name: 'HASH & CHEESE BREAKFAST BOWL',
                description: '¡Irresistibles! Hashbrown tater crujientes hechos al horno, \n' +
                    'huevos fritos, bacon crispy, bañado en una salsa de 4 quesos y un \n' +
                    'toque de cebollina.',
                price: 8.00,
                tags: ['Breakfast', 'Classic'],
                image: 'hash-cheese-breakfast-bowl.jpg'
            },
            {
                name: 'CHICKEN WAFFLE 2.0',
                description: 'Nuestro Chicken Waffle 2.0 presenta un waffle estilo belga crujiente \n' +
                    'y dorado, acompañado de trozos de milanesa de pollo, crujiente \n' +
                    'bacon, un toque de maple para darle ese sabor agridulce \n' +
                    'característico, y cebollina fresco como decoración.',
                price: 12,
                tags: ['Special', 'Original Recipe', 'Brunch'],
                image: 'chicken-waffle.jpg'
            }
        ]
    },
    {
        category: 'SANDWICHES',
        subtitle: 'EVERYDAY MORNING FIX',
        type: 'food',
        items: [
            {
                name: 'EGGS & TRUFFLE BREAKFAST SANDWICH',
                description: 'Una deliciosa combinación de huevo, mayo de trufa, tempura flakes \n' +
                    'y cebollina cortada, todo en pan brioche de molde de masa madre, \n' +
                    'que te dejará queriendo más',
                price: 7.75,
                tags: ['Breakfast', 'Special', 'Original Recipe'],
                image: 'eggs-truffle-sandwich.jpg'
            },
            {
                name: 'EVERYDAY BREAKFAST SANDWICH',
                description: 'El clásico desayuno hecho sandwich. Pan brioche de molde de masa \n' +
                    'madre relleno de huevo, sofrito de tomate, salchicha artesanal de \n' +
                    'desayuno, bacon y cebollina cortada, ¡perfecto para cualquier día \n' +
                    'de la semana!',
                price: 9.00,
                tags: ['Breakfast', 'Classic'],
                image: 'everyday-breakfast-sandwich.jpg'
            },
            {
                name: 'THE SALMON ROLL',
                description: 'Te apuesto que no has probado esto! Pan brioche de masa madre \n' +
                    'con salmón ahumado, kani-tuna, aguacate y mayonesa picante con \n' +
                    'toques de tempura flakes',
                price: 8.50,
                tags: ['Special', 'Original Recipe'],
                image: 'the-salmon-roll.jpg'
            },
            {
                name: 'THE HOT DOG ROLL',
                description: 'Saborea nuestra versión especial de un hot dog. Preparado con pan \n' +
                    'brioche de masa madre, salchicha artesanal de desayuno, refrito de \n' +
                    'tomate y cebolla, mayo picante, tempura flakes y cebollina fresca.',
                price: 8.50,
                tags: ['Special', 'Original Recipe'],
                image: 'the-hot-dog-roll.jpg'
            }
        ]
    },
    {
        category: 'FOCACCIAS AND SANDWICHES',
        subtitle: 'BIG BITES',
        type: 'food',
        items: [
            {
                name: 'CHICKEN FOCACCIA',
                description: 'Focaccia de masa madre con milanesa de pollo, refrito de tomate, \n' +
                    'queso mozzarella rallado, queso parmesano, acompañada con salsa \n' +
                    'especial leto y un shot de gazpacho',
                price: 13.5,
                tags: ['Special', 'Original Recipe'],
                image: 'chicken-focaccia.jpg'
            },
            {
                name: 'SHORT RIB SANDWICH',
                description: 'Saborea la perfección con nuestro sandwich de short ribs, mozzarella \n' +
                    'ahumada de quesos Chela, cebolla caramelizada y salsa de mostaza \n' +
                    'antigua, servido en pan campesino de masa madre con nuestra \n' +
                    'deliciosa salsa leto para dippear y un shot de gazpacho',
                price: 15.95,
                tags: ['Special', 'Original Recipe'],
                image: 'short-rib-sandwich.jpg'
            },
            {
                name: 'GRILLED CHICKEN SANDWICH',
                description: 'Focaccia de masa madre con milanesa de pollo, refrito de tomate, \n' +
                    'queso mozzarella rallado, queso parmesano, acompañada con salsa \n' +
                    'especial leto y un shot de gazpacho',
                price: 13.50,
                tags: ['Classic', 'Special'],
                image: 'grilled-chicken-sandwich.jpg'
            },
            {
                name: 'VEGAN PASTRAMI FOCACCIA',
                description: 'Focaccia de masa madre con pastrami vegano de planticeria, \n' +
                    'lechuga, tomate, queso vegano estilo cheddar y aguacate.  \n' +
                    'Acompañada con salsa especial leto vegana y un shot de gazpacho.',
                price: 13.50,
                tags: ['Vegan', 'Healthy', 'Special'],
                image: 'vegan-pastrami-focaccia.jpg'
            },
            {
                name: 'ROAST BEEF FOCACCIA',
                description: 'Focaccia de masa madre con roastbeef, queso suizo, kimchi y \n' +
                    'cebollina cortada, acompañada de nuestra salsa especial leto y un \n' +
                    'shot de gazpacho',
                price: 13.5,
                tags: ['Special', 'Original Recipe'],
                image: 'roast-beef-focaccia.jpg'
            }
        ]
    },
    {
        category: 'TOASTS AND OMELETTES',
        subtitle: 'THE MOST IMPORTANT MEAL OF THE DAY',
        type: 'food',
        items: [
            {
                name: 'THE TROPICAL TOAST',
                description: 'Una combinación perfecta encima de una tostada de pan campesino\n' +
                    'de masa madre, plátano maduro frito en aceite de coco, queso \n' +
                    'blanco prensado del país, miel de caña picante y almendra fileteada\n' +
                    'tostada.',
                price: 9.00,
                tags: ['Breakfast', 'Special', 'Original Recipe'],
                image: 'tropical-toast.jpg'
            },
            {
                name: 'MEDITERRANEAN TOAST',
                description: 'Una combinación exquisita en una tostada de pan campesino\n' +
                    'de masa madre, escalivada (pimiento, berenjena y cebolla asada), \n' +
                    'queso de cabra, albahaca fresca y aceitunas negras, que te\n' +
                    'transportará a la cocina mediterránea.',
                price: 9.95,
                tags: ['Vegetarian', 'Healthy', 'Special'],
                image: 'mediterranean-toast.jpg'
            },
            {
                name: 'AVO TOAST 2.0',
                description: 'Una explosión de sabores y texturas en cada bocado. Tostada de pan\n' +
                    'campesino de masa madre cubierto con crema de aguacate, rodajas \n' +
                    'de aguacate fresco, vinagreta de kombucha y balsámico, kale rizado, \n' +
                    'semillas de girasol y calabaza, granada y un toque de microgreens',
                price: 9.00,
                tags: ['Vegetarian', 'Healthy', 'Special'],
                image: 'avo-toast.jpg'
            },
            {
                name: 'BACK TO BASICS EGGS',
                description: 'Qué podemos decir... un plato que puedes armar con los huevos \n' +
                    'al gusto, aguacate, salmón ahumado o bacon y tostadas de pan \n' +
                    'campesino de masa madre.',
                price: 10.00,
                tags: ['Breakfast', 'Classic'],
                image: 'back-to-basic-eggs.jpg'
            },
            {
                name: 'SAUTÉ AND SAUSAGE OMELETTE',
                description: 'Un omelette relleno de sabor con huevo, refrito de tomate, \n' +
                    'salchicha artesanal de desayuno, todo acompañado de tostadas \n' +
                    'de pan campesino de masa madre. Opción vegetariana disponible.',
                price: 8.75,
                tags: ['Breakfast', 'Classic'],
                image: 'saute-and-sausage-omelette.jpg'
            },
            {
                name: 'HAM & CHEESE OMELETTE',
                description: 'Nuestro omelette clásico con jamón cocido y queso mozzarella \n' +
                    'rallado, acompañado de tostadas de pan campesino de masa madre.',
                price: 8.75,
                tags: ['Breakfast', 'Classic'],
                image: 'ham-cheese-omelette.jpg'
            }
        ]
    },
    {
        category: 'BOWLS',
        subtitle: 'IN BOWLS WE TRUST',
        type: 'food',
        items: [
            {
                name: 'AÇAÍ BOWL',
                description: 'Acai de amazonas, granola casero, mantequilla de maní, \n' +
                    'coco flakes tostado, goji berries y nuevo topping berries congelados.',
                price: 9.00,
                tags: ['Healthy', 'Vegetarian', 'Special'],
                image: 'acai-bowl.jpg'
            },
            {
                name: 'MASON JARS',
                description: 'Deliciosos minibowls en los que puedes escoger entre yogurt griego\n' +
                    'orgánico, pudín de chía vegano, u overnight oats como base y en los\n' +
                    'que puedes agregar toppings a tu gusto.',
                price: 5.00,
                tags: ['Healthy', 'Vegetarian', 'Special'],
                image: 'mason-jars.jpg'
            }
        ]
    },
    // Drinks Categories
    {
        category: 'LETO COFFEE ROASTED',
        subtitle: 'SPECIALTY COFFEE SELECTION',
        type: 'drinks',
        items: [
            {
                name: 'GEISHA, TRADICIONAL NATURAL, 125gr',
                description: 'Perfil de sabor:\n' +
                    'Alta acidez, sabor exquisito con notas brillantes de bergamota, \n' +
                    'jazmín y lemongrass.\n' +
                    'El sabor se complementa con notas dulces de moras negras y, \n' +
                    'en el retrogusto, se encuentra una elegante dulzura frutal.',
                price: 35,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'mil-cumbres-geisha.jpg'
            },
            {
                name: 'MARAGOGIPE, TRADICIONAL NATURAL, 225gr',
                description: 'Perfil de sabor:\n' +
                    'Acidez media, sabor intenso con notas brillantes de manzana roja \n' +
                    'y ciruela seca, complementadas con matices de chocolate.\n' +
                    'En el retrogusto, se percibe un ligero sabor cremoso.\n',
                price: 19,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'hartmann-maragogipe.jpg'
            },
            {
                name: 'PACAMARA, TRADICIONAL NATURAL, 225gr',
                description: 'Perfil de sabor: Baja acidez,\n' +
                    'Notas intensas de chocolate, matizadas por el dulce néctar de la miel.\n' +
                    'Sutiles toques de naranja madura se entrelazan con la jugosidad \n' +
                    'de la pulpa de café.\n' +
                    'El final delicado se adorna con el aroma etéreo y amargo \n' +
                    'de la cáscara de pomelo.\n',
                price: 19,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'mil-cumbres-pacamara.jpg'
            },
            {
                name: 'CATURRA, NATURAL, 225gr',
                description: 'Perfil de sabor:\n' +
                    'Acidez brillante,\n' +
                    'Notas dulces de frutas tropicales (como mango y piña) y matices \n' +
                    'suaves de chocolate y caramelo.\n' +
                    'En el retrogusto, se percibe una nota floral ligera.',
                price: 19,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'corpachi-caturra.jpg'
            },
            {
                name: 'SAN RAMON, TRADICIONAL NATURAL, 225gr',
                description: 'Perfil de sabor:\n' +
                    'Acidez media,\n' +
                    'Sabor intenso con notas brillantes de frutas rojas \n' +
                    '(como manzana y ciruela), complementadas con matices de \n' +
                    'cedro y almendra.\n' +
                    'En el retrogusto, se percibe un ligero sabor cremoso.',
                price: 19,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'mil-cumbres-san-ramon.jpg'
            }
        ]
    },
    {
        category: 'MÉTODOS DE PREPARACIÓN',
        subtitle: 'ELIGE TU MÉTODO DE PREPARACIÓN',
        type: 'drinks',
        items: [
            {
                name: 'V60',
                description: 'Este método de preparación resalta la acidez brillante y las notas \n' +
                    'afrutadas del café, creando un sabor limpio y equilibrado con un \n' +
                    'cuerpo ligero. \n' +
                    ' EXTRA: Geisha +$2',
                price: 4.50,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'v-60.jpg'
            },
            {
                name: 'CHEMEX',
                description: 'Con Chemex, el café adquiere un sabor suave con una dulzura \n' +
                    'destacada y notas florales, resaltando la pureza y suavidad de \n' +
                    'la bebida gracias a una extracción lenta.\n' +
                ' EXTRA: Geisha +$2',
                price: 7.50,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'chemex.jpg'
            },
            {
                name: 'AEROPRESS',
                description: 'Este método produce un café con cuerpo denso y un sabor \n' +
                    'aterciopelado y equilibrado, revelando tanto notas afrutadas \n' +
                    'como matices de chocolate.\n' +
                    ' EXTRA: Geisha +$2',
                price: 4.50,
                tags: ['Hot', 'Coffee Based', 'Specialty'],
                image: 'aeropress.jpg'
            },
            {
                name: 'COLD BREW, CATURRA, NATURAL',
                description: 'El Cold Brew revela un sabor suave y dulce con baja acidez y mínima \n' +
                    'amargura. Gracias a la extracción prolongada en frío, la bebida \n' +
                    'adquiere un cuerpo denso y notas intensas de chocolate, nueces y \n' +
                    'caramelo, lo que la convierte en una bebida perfectamente \n' +
                    'equilibrada y refrescante.\n',
                price: 5.50,
                tags: ['Cold', 'Coffee Based', 'Specialty'],
                image: 'cold-brew.jpg'
            },
        ]
    },
    {
        category: 'CAFÉ CLÁSICO',
        subtitle: 'ORIGINAL LETO RECETAS',
        type: 'drinks',
        items: [
            {
                name: 'ESPRESSO',
                secondaryName: 'ESPRESSO DOUBLE', // Второе название
                description: 'Extracto concentrado de café elaborado a alta presión, \n' +
                    'con un ratio clásico de 1:2 entre el café y el agua, resultando en \n' +
                    'un sabor intenso y equilibrado.',
                price: 3.25, // Первая цена
                secondaryPrice: 3.75, // Вторая цена
                tags: ['Hot', 'Coffee Based', 'Classic'],
                image: 'espresso.jpg'
            },
            {
                name: 'MACCHIATO ',
                secondaryName: 'MACCHIATO DOUBLE', // Второе название
                description: 'Un shots de espresso marcado con una pequeña cantidad de leche \n' +
                    'espumada, resaltando el intenso sabor del café con un toque suave \n' +
                    'y cremoso.',
                price: 3.5, // Первая цена
                secondaryPrice: 4, // Вторая цена
                tags: ['Hot', 'Coffee Based', 'Classic'],
                image: 'macchiato.jpg'
            },
            {
                name: 'CAPPUCCINO  ',
                secondaryName: 'CAPPUCCINO  DOUBLE', // Второе название
                description: 'Combinación de un doble shot de espresso con una espuma de leche\n' +
                    'densa y elástica. La textura es aterciopelada, creando un equilibrio \n' +
                    'perfecto entre la cremosidad de la leche y la intensidad del café.',
                price: 4, // Первая цена
                secondaryPrice: 5, // Вторая цена
                tags: ['Hot', 'Coffee Based', 'Classic'],
                image: 'cappuccino.jpg'
            },
            {
                name: 'LATTE ',
                description: 'Un doble shot de espresso suavemente equilibrado con una generosa \n' +
                    'cantidad de leche vaporizada. La textura es sedosa, envolviendo el \n' +
                    'paladar con una cremosidad ligera, mientras el café mantiene su \n' +
                    'carácter distintivo sin dominar. Ideal para quienes buscan una \n' +
                    'experiencia más suave y aterciopelada.',
                price: 5,
                tags: ['Hot', 'Coffee Based', 'Classic'],
                image: 'latte.jpg'
            },
            {
                name: 'FLAT WHITE',
                description: 'Originario de Nueva Zelanda, el flat white es una bebida cremosa \n' +
                    'de café con leche menos emulsionada, permitiendo que el sabor \n' +
                    'del espresso sea más destacado. En 2016, fuimos la primera \n' +
                    'cafetería en Panamá en ofrecer el flat white, marcando tendencia \n' +
                    'en la escena local del café. ',
                price: 4.75,
                tags: ['Hot', 'Coffee Based', 'Classic'],
                image: 'flat-white.jpg'
            },
        ]
    },
    {
        category: 'CAFÉ EXCLUSIVO',
        subtitle: 'ORIGINAL SIGNATURE RECETAS',
        type: 'drinks',
        items: [
            {
                name: 'RAF VAINILLA',
                description: 'Un clásico dentro de los cafés especiales, el RAF nació en Moscú a \n' +
                    'finales de los 90. Nuestro RAF combina espresso con nata ligera y \n' +
                    'vainilla, creando una bebida que recuerda al sabor de un helado \n' +
                    'derretido, suave y reconfortante.',
                price: 7,
                tags: ['Hot', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'raf-vainilla.jpg'
            },
            {
                name: 'RAF DE JENGIBRE',
                description: 'Nuestra versión artesanal del clásico RAF incorpora un sirope casero \n' +
                    'de jengibre que le aporta un toque picante y reconfortante.',
                price: 7,
                tags: ['Hot', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'raf-jengibre.jpg'
            },
            {
                name: 'RAF DE NARANJA',
                description: 'Este RAF es un auténtico deleite cítrico. El sirope artesanal de \n' +
                    'cáscara de naranja aporta un toque vibrante de jugosa naranja a \n' +
                    'la cremosa textura de la bebida.',
                price: 7,
                tags: ['Hot', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'raf-naranja.jpg'
            },
            {
                name: 'LATTE SALTED CARAMEL',
                description: 'El equilibrio perfecto entre lo dulce y lo salado en este latte. \n' +
                    'El caramelo salado hecho en casa le da al café una dulzura \n' +
                    'moderada y suave de caramelo, realzada por el sabor de la sal \n' +
                    'del Himalaya.',
                price: 7,
                tags: ['Hot', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'latte-salted-caramel.jpg'
            },
            {
                name: 'LATTE LEMONGRASS',
                description: 'Nuestro latte exclusivo con una salsa casera de lemongrass que nos \n' +
                    'transporta a los sabores y culturas del sudeste asiático. La base \n' +
                    'cremosa con un toque de lemongrass y el intenso sabor a chocolate \n' +
                    'del espresso',
                price: 7,
                tags: ['Hot', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'latte-lemongrass.jpg'
            },
            {
                name: 'MOCKACHINO ',
                description: 'Nuestra receta exclusiva de salsa de chocolate casera combina la \n' +
                    'intensidad del café con la suavidad del chocolate belga al 56%. \n' +
                    'El mochaccino tiene un sabor rico, aterciopelado y una dulzura moderada.',
                price: 7,
                tags: ['Hot', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'mockachino.jpg'
            },
            {
                name: 'MOCKACHINO PEANUT',
                description: 'Nuestra receta original de mochaccino, en la que hemos recreado \n' +
                    'el sabor de la barra de chocolate "Snickers". Salsa casera \n' +
                    'de chocolate, caramelo y crema de maní, combinados con \n' +
                    'un shot de espresso',
                price: 7,
                tags: ['Hot', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'mockachino-peanut.jpg'
            },
        ]
    },

    {
        category: 'BEBIDAS EXCLUSIVAS',
        subtitle: 'SIN CAFE',
        type: 'drinks',
        items: [
            {
                name: 'KYOTO FOG (NEW)',
                description: 'Latte a base de sirope casero de caramelo y extracto de vainilla \n' +
                    'mezclado con un shot de hojicha. Hojicha es un té japonés hecho \n' +
                    'de hojas de té tostadas, que apareció por primera vez en Kioto en \n' +
                    'la década de 1920. Tiene un sabor suave, ligeramente a nuez y \n' +
                    'ahumado, con bajo contenido de cafeína.',
                price: 5,
                tags: ['Hot', 'No Coffee', 'Specialty', 'New'],
                image: 'kyoto-fog.jpg'
            },
            {
                name: 'LONDON FOG',
                description: 'Latte a base de sirope casero de té Earl Grey con extracto natural de \n' +
                    'vainilla y polvo de cacao. La receta fue perfeccionada a partir de una \n' +
                    'bebida original que probamos por primera vez en Canadá y la \n' +
                    'incluimos en nuestro menú en 2017.',
                price: 5,
                tags: ['Hot', 'No Coffee', 'Specialty'],
                image: 'london-fog.jpg'
            },
            {
                name: 'LATTE CÁSCARA',
                description: 'Latte a base de sirope casero de cáscara — la piel de la cereza de \n' +
                    'café. Tiene un sabor único con notas de bayas y frutos secos. \n' +
                    'La cáscara es rica en antioxidantes, vitaminas y minerales.',
                price: 5,
                tags: ['Hot', 'No Coffee', 'Specialty', 'Original Recipe'],
                image: 'latte-cascara.jpg'
            },
            {
                name: 'LATTE SPICED MATCHA (NEW)',
                description: 'Nuestro jarabe especiado exclusivo, que combinamos con un shot \n' +
                    'de matcha japonesa.',
                price: 7,
                tags: ['Hot', 'No Coffee', 'Specialty', 'New'],
                image: 'latte-spiced-matcha.jpg'
            },
            {
                name: 'CACAO 8 OZ',
                secondaryName: 'CACAO 12 OZ', // Второе название
                description: 'Nuestra receta clásica de salsa de chocolate hecha con chocolate \n' +
                    'belga al 56%, que preparamos todos los días. Tiene una dulzura \n' +
                    'moderada y un sabor intenso.',
                price: 5, // Первая цена
                secondaryPrice: 7, // Вторая цена
                tags: ['Hot', 'No Coffee', 'Classic'],
                image: 'cacao.jpg'
            },
            {
                name: 'LATTE HOJICHA',
                description: 'Latte a base de hojicha. Hojicha es un té japonés hecho de hojas \n' +
                    'de té tostadas. Apareció por primera vez en Kioto en la década \n' +
                    'de 1920. Tiene un sabor suave, ligeramente a nuez y ahumado, \n' +
                    'con bajo contenido de cafeína.',
                price: 5,
                tags: ['Hot', 'No Coffee', 'Specialty'],
                image: 'latte-hojicha.jpg'
            },
            {
                name: 'LATTE MATCHA',
                description: 'Es un té japonés hecho de hojas de té verde finamente molidas y se \n' +
                    'utilizaba en ceremonias del té hace muchos siglos en Japón. Tiene \n' +
                    'un sabor vegetal intenso y un ligero amargor. Contiene una alta \n' +
                    'concentración de antioxidantes y teína natural, que proporciona \n' +
                    'energía y mejora la concentración.',
                price: 5,
                tags: ['Hot', 'No Coffee', 'Specialty'],
                image: 'latte-matcha.jpg'
            },
            {
                name: 'LATTE BLUE MATCHA',
                description: 'Latte a base de hojas molidas de la flor de mariposa, popular en el \n' +
                    'sudeste asiático. Tienen un sabor ligeramente dulce y no contienen \n' +
                    'cafeína. En el latte, también añadimos extracto de jengibre y azúcar \n' +
                    'de coco.',
                price: 5.25,
                tags: ['Hot', 'No Coffee', 'Specialty'],
                image: 'latte-blue-matcha.jpg'
            },
            {
                name: 'LATTE MATCHA CARAMEL',
                description: 'Latte a base de una salsa de caramelo casero, suave y espeso, con \n' +
                    'un toque de sal del Himalaya y un shot de matcha.',
                price: 7,
                tags: ['Hot', 'No Coffee', 'Specialty', 'Original Recipe'],
                image: 'latte-matcha-caramel.jpg'
            },
            {
                name: 'LATTE BLUE CARAMEL',
                description: 'Latte a base de una salsa de caramelo casero, suave y espeso, con \n' +
                    'un toque de sal del Himalaya y un shot de blue matcha.',
                price: 5.25,
                tags: ['Hot', 'No Coffee', 'Specialty', 'Original Recipe'],
                image: 'latte-blue-matcha-caramel.jpg'
            },
        ]
    },

    {
        category: 'CAFÉ FRIO',
        subtitle: 'SIGNATURE DRINK',
        type: 'drinks',
        items: [
            {
                name: 'PASSIONFRUIT ESPRESSO',
                description: 'Si te gusta el bumble bee, esta bebida es para ti. Sirope de \n' +
                    'maracuya casero, un toque de jugo de limón, agua con gas y un \n' +
                    'shot de espresso.',
                price: 5,
                tags: ['Cold', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'passionfruit-espresso.jpg'
            },
            {
                name: 'ESPRESSO TÓNICO',
                description: 'Perfil de sabor:\n' +
                    'Bebida a base de espresso con sirope de jengibre, jugo de limón, \n' +
                    'hierba buena y agua con gas.',
                price: 6,
                tags: ['Cold', 'Coffee Based', 'Specialty', 'Original Recipe'],
                image: 'espresso-tonico.jpg'
            },
            {
                name: 'BUMBLE BEE',
                description: 'Nuestra receta original de 2017. Bebida dulce y refrescante, \n' +
                    'preparada con una mezcla de jugo de naranja natural, sirope de \n' +
                    'cáscara de geisha y un shot de espresso.',
                price: 5,
                tags: ['Cold', 'Coffee Based', 'Original Recipe'],
                image: 'bumble-bee.jpg'
            },
        ]
    },

    {
        category: 'LIMONADAS FRIO',
        subtitle: 'SIGNATURE DRINK',
        type: 'drinks',
        items: [
            {
                name: 'LIMONADA OLDSCHOOL',
                description: 'Jugó de limon, hierba buena, azúcar y agua natural o agua con gas.',
                price: 6,
                tags: ['Cold', 'No Coffee', 'Classic'],
                image: 'limonada-oldschool.jpg'
            },
            {
                name: 'LIMONADA GREEN KUSH',
                description: 'Bebida a base de té de matcha con sirope de jengibre, jugo de \n' +
                    'limón, hierba buena y agua con gas.',
                price: 6,
                tags: ['Cold', 'No Coffee', 'Specialty', 'Original Recipe'],
                image: 'limonada-green-kush.jpg'
            },
            {
                name: 'LIMONADA ROMERO Y CRANBERRY',
                description: 'Bebida a base de siropes de romero y cranberry, jugo de limón y \n' +
                    'agua con gas',
                price: 6,
                tags: ['Cold', 'No Coffee', 'Specialty', 'Original Recipe'],
                image: 'limonada-romero-cranberry.jpg'
            },
            {
                name: 'TANGERINE REFRESHER (NEW)',
                description: 'Mezcla refrescante de sirope de mandarina, jugo de limón, te verde \n' +
                    'y agua con gas o agua natural',
                price: 5,
                tags: ['Cold', 'No Coffee', 'New', 'Specialty'],
                image: 'tangerine-refresher.jpg'
            },
        ]
    },
    {
        category: 'KOMBUCHA',
        subtitle: 'ARTESANAL DE LA CASA',
        type: 'drinks',
        items: [
            {
                name: 'COSMOKOMBUCHA',
                description: 'La kombucha es una bebida fermentada a base de té, conocida por \n' +
                    'sus propiedades beneficiosas para la digestión y el sistema \n' +
                    'inmunológico. Preparamos nuestra kombucha según una receta \n' +
                    'original y, de manera periódica, creamos nuevos sabores para \n' +
                    'ofrecer una experiencia única y variada.',
                price: 5,
                tags: ['Cold', 'No Coffee', 'Healthy', 'Original Recipe'],
                image: 'kombucha.jpg'
            },
            {
                name: 'KOMBUCHA, 1L',
                description: 'CONSULTE EL SABOR DISPONIBLE CON NUESTROS BARISTAS.',
                price: 6,
                tags: ['Cold', 'No Coffee', 'Healthy', 'Original Recipe'],
                image: 'kombucha-1l.jpg'
            },
            ]
    }
];

export default menuCategories;