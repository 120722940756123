import styled from 'styled-components';
import { motion } from 'framer-motion';

export const NavContainer = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: ${({ theme }) => theme.spacing.md};
    background-color: ${({ theme }) => theme.colors.background};
    z-index: 1000;
`;

export const NavList = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.lg}; // Увеличиваем расстояние между элементами
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    padding: 0 ${({ theme }) => theme.containerPadding};
    justify-content: flex-start; // Выравнивание по левому краю
`;

export const NavButton = styled(motion.button)`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.text};
    border: none;
    padding: ${({ theme }) => theme.spacing.xs} 0;
    font-size: ${({ theme }) => theme.fontSize.xxl}; // Увеличенный размер шрифта
    font-family: 'Bebas Neue', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
    letter-spacing: 1.5px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -2px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.text};
        transition: width 0.3s ease;
    }

    &:hover:after,
    &.active:after {
        width: 100%;
    }

    &.active {
        color: ${({ theme }) => theme.colors.text};
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    }

    @media (max-width: 768px) {
        font-size: ${({ theme }) => theme.fontSize.xl};
        padding: ${({ theme }) => theme.spacing.xs} 0;
    }

    @media (max-width: 480px) {
        font-size: ${({ theme }) => theme.fontSize.lg};
        letter-spacing: 1px;
    }
`;