import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as S from './Products.styles';
import { productsData } from '../../../data/productsData';

const Products: React.FC = () => {
    const [expandedProduct, setExpandedProduct] = useState<string | null>(null);

    const toggleProduct = (productId: string) => {
        if (expandedProduct === productId) {
            setExpandedProduct(null);
        } else {
            setExpandedProduct(productId);
        }
    };

    return (
        <S.ProductsContainer>
            <S.ProductsContent>
                <S.Title>OUR COFFEE</S.Title>
                <AnimatePresence mode="wait">
                    <S.ProductsGrid
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {productsData.map((product, index) => (
                            <motion.div
                                key={product.id}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: index * 0.1 }}
                            >
                                <S.ProductCard
                                    onClick={() => toggleProduct(product.id)}
                                    whileHover={!expandedProduct ? {
                                        y: -10,
                                        scale: 1.03,
                                        boxShadow: '0 16px 32px rgba(0, 0, 0, 0.2)',
                                    } : {}}
                                    animate={expandedProduct === product.id ? {
                                        scale: 1.03,
                                        boxShadow: '0 16px 32px rgba(0, 0, 0, 0.2)',
                                    } : {
                                        scale: 1,
                                        boxShadow: 'none',
                                    }}
                                >
                                    <S.ProductImage
                                        src={`/image/${product.image}`}
                                        alt={product.name}
                                        loading="lazy"
                                    />
                                    <S.ProductContent>
                                        <S.ProductName>{product.name}</S.ProductName>

                                        <AnimatePresence>
                                            {expandedProduct === product.id && (
                                                <S.ExpandedContent
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                    exit={{ opacity: 0, height: 0 }}
                                                    transition={{ duration: 0.3 }}
                                                >
                                                    <S.TagsContainer>
                                                        {product.tags.map((tag) => (
                                                            <S.Tag key={tag}>{tag}</S.Tag>
                                                        ))}
                                                    </S.TagsContainer>
                                                    <S.ProductInfo>
                                                        <S.ProductType>{product.type}</S.ProductType>
                                                        <S.ProductDescription>
                                                            {product.description}
                                                        </S.ProductDescription>
                                                        <S.PriceWrapper>
                                                            <S.ProductPrice>${product.price.toFixed(2)}</S.ProductPrice>
                                                        </S.PriceWrapper>
                                                    </S.ProductInfo>
                                                </S.ExpandedContent>
                                            )}
                                        </AnimatePresence>

                                        <S.BuyButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                // Здесь логика покупки
                                            }}
                                        >
                                            Buy
                                        </S.BuyButton>
                                    </S.ProductContent>
                                </S.ProductCard>
                            </motion.div>
                        ))}
                    </S.ProductsGrid>
                </AnimatePresence>
            </S.ProductsContent>
        </S.ProductsContainer>
    );
};

export default Products;